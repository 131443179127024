<template>
  <!-- 双随机抽检-报警合并-疲劳驾驶 -->
  <div class="fatigue-driving" ref="fatigueDriving">
    <el-tabs type="border-card" @tab-click="clickTab" v-model="form.type">
      <el-tab-pane label="统计" name="1">
        <div class="form-area" ref="form">
          <analysis-form @getFormVal="onSubmit" :showCar="false" :chooseToday="true">
            <el-button
              type="primary"
              size="small"
              :disabled="comArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(1)"
              >导出</el-button
            >
          </analysis-form>
          <el-radio-group v-model="state">
            <el-radio :label="0"> 图表 </el-radio>
            <el-radio :label="1"> 报表 </el-radio>
          </el-radio-group>
        </div>
        <div class="item item2 width1" v-if="state == 0" style="height: 400px">
          <charts :option="item2Option" :id="item2"></charts>
        </div>

        <!-- 企业统计 -->
        <company-table
          ref="pagination1"
          :height="tableHeight"
          :total="total"
          :tableData="comArr"
          v-else
        />
      </el-tab-pane>
      <el-tab-pane label="车辆" name="2">
        <div class="form-area">
          <analysis-form @getFormVal="onSubmit" :chooseToday="true">
            <el-button
              type="primary"
              size="small"
              :disabled="carArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(2)"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 车辆明细 -->
        <car-table
          ref="pagination2"
          :height="tableHeight"
          :modelObj="modelObj"
          :typeObj="typeObj"
          :total="total"
          :tableData="carArr"
          @sendInfo="getPageInfo"
        />
      </el-tab-pane>
      <el-tab-pane label="详情" name="3">
        <alarmDetail
          :height="tableHeight"
          :alarmType="alarmType"
        ></alarmDetail>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import companyTable from "./components/companyTableFatigue.vue";
import carTable from "./components/carTableFatigue.vue";
import charts from "@/components/charts/charts.vue";
import alarmDetail from "./components/alarmDetail"

import {
  queryVehicleFatigueStatisticsAPI,
  queryCompanyFatigueStatisticsAPI,
} from "@/api/lib/gps-api.js";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";

import { queryDictsByCodes } from "@/api/lib/api.js";

import {
  formatDict,
  getRangeDay,
  formatDate,
  formatDay,
  getCurent,
} from "@/common/utils/index";
export default {
  name: "fatigueDriving",
  components: {
    charts,
    analysisForm,
    companyTable,
    carTable,
    alarmDetail
  },
  data () {
    return {
      item2: "itemChart2",
      item2Option: {
        title: {
          text: "疲劳报警统计",
          left: 0,
          textStyle: {
            color: "#666666",
            fontSize: 16,
          },
          top: 0,
        },
        grid: {
          top: 45,
          left: 40,
          right: 40,
          bottom: 20,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          textStyle: {
            fontSize: 12,
          },
        },
        color: ["#FFD616", "#0B4FD5", "#AD8FDF", "#02CDCE", "#EA546D", "#336FFE"],
        legend: {
          right: 0,
          textStyle: {
            color: "#333",
          },
          orient: "horizontal",
          itemWidth: 4,
          itemHeight: 4,
          itemGap: 10,
        },
        dataset: {
          dimensions: ["product", "单次超时疲劳数量", "累计超时疲劳数量", "生理疲劳数量", "报警车辆"],
          source: [
            {
              product: formatDay(getRangeDay()[0]),
              单次超时疲劳数量: 0,
              累计超时疲劳数量: 0,
              生理疲劳数量: 0,
              报警车辆: 0,
            },
            {
              product: formatDay(getRangeDay()[1]),
              单次超时疲劳数量: 0,
              累计超时疲劳数量: 0,
              生理疲劳数量: 0,
              报警车辆: 0,
            },
          ],
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLabel: {
            color: "#666666",
            fontSize: 12,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#666666",
            fontSize: 12,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#E9E9E9",
            },
          },
        },
        series: [{ type: "line" }, { type: "line" }, { type: "line" }, { type: "line" }],
      },
      tableHeight: 0,
      tableData: [],
      state: 0,
      form: {
        type: "1",
        vehicleNos: [],
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,
      },
      carArr: [], //   车辆明细
      comArr: [],
      total: 0,
      typeObj: null,
      modelObj: null,
      exportForm: {},
      downLoadStatus: false,
      alarmType: [1001],//疲劳驾驶
      alarmTypeList: [
        {
          dictValue: "疲劳驾驶报警",
          dictCode: 1001
        }
      ],
    };
  },
  computed: {
    chartsWidth () {
      return {
        width0: this.comArr.length <= 5,
        width1: this.comArr.length <= 15 && this.comArr.length > 5,
        width2: this.comArr.length <= 31 && this.comArr.length > 15,
      };
    },
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.fatigueDriving.clientHeight;
      const formHeight = this.$refs.form.clientHeight || 90;
      this.tableHeight = wholeHeight - formHeight - 40 - 30 - 10 - 24;
    },
    //导出
    exportData (key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId") || localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName:
          key === 1 ? `疲劳驾驶统计-${getCurent()}` : `疲劳驾驶详情-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:
          key === 1
            ? `/checkVehicleFatigue/fatigueCompanyExport`
            : `/checkVehicleFatigue/fatigueVehicleExport`,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 提交
    onSubmit (value) {
      this.form = { ...this.form, ...value };
      this.form.currentPage = 1;
      this.getDataList();
    },

    // 获取列表
    getDataList () {
      let data = { ...this.form };
      delete data.type;

      switch (this.form.type) {
        case "2":
          /**获取车辆详情*/
          queryVehicleFatigueStatisticsAPI(data).then((res) => {
            if (res.code === 1000) {
              this.carArr = res.data.list;
              this.total = res.data.total;
            } else {
              this.carArr = [];
              this.total = 0;
            }
          });
          break;
        case "1":
          /**获取企业统计*/
          delete data.vehicleNos;
          data.pageSize = 100;
          data.currentPage = 1;
          queryCompanyFatigueStatisticsAPI(data).then((res) => {
            if (res.code === 1000 && res.data.list.length > 0) {
              this.comArr = res.data.list;
              this.total = res.data.total;
              this.renderLine(res.data.list);
            } else {
              this.comArr = [];
              this.total = 0;
              let source = [
                {
                  product: formatDay(getRangeDay()[0]),
                  单次超时疲劳数量: 0,
                  累计超时疲劳数量: 0,
                  生理疲劳数量: 0,
                  报警车辆: 0,
                },
                {
                  product: formatDay(getRangeDay()[1]),
                  单次超时疲劳数量: 0,
                  累计生理疲劳数量: 0,
                  报警车辆: 0,
                },
              ];
              this.$set(this.item2Option.dataset, "source", source);
            }
          });
      }
      this.exportForm = { ...data };
    },
    renderLine (res) {
      let source = [];
      res.forEach((element) => {
        source.push({
          product: element.statisticsKey,
          // 超时疲劳数量: element.overTimeNum,
          单次超时疲劳数量: element.singleOvertimeNum,
          累计超时疲劳数量: element.accumulateOvertimeNum,
          生理疲劳数量: element.physiologyNum,
          报警车辆: element.alarmVehicleNum,
        });
      });
      this.$set(this.item2Option.dataset, "source", source);
    },
    /**切换统计|详情 */
    clickTab () {
      this.total = 0;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.comArr = [];
      this.carArr = [];
      let source = [
        {
          product: formatDay(getRangeDay()[0]),
          单次超时疲劳数量: 0,
          累计超时疲劳数量: 0,
          生理疲劳数量: 0,
          报警车辆: 0,
        },
        {
          product: formatDay(getRangeDay()[1]),
          单次超时疲劳数量: 0,
          累计超时疲劳数量: 0,
          生理疲劳数量: 0,
          报警车辆: 0,
        },
      ];
      this.$set(this.item2Option.dataset, "source", source);
    },
    /**分页参数切换 */
    getPageInfo (params) {
      this.form.currentPage = params.currentPage;
      this.form.pageSize = params.pageSize;
      if (this.comArr.length === 0 && this.carArr.length === 0) return;
      this.getDataList();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "VETY,CPYS" }).then((res) => {
        if (res.code === 1000) {
          this.modelObj = formatDict(res.data.VETY);
          this.typeObj = formatDict(res.data.CPYS);
        }
      });
    },
  },
  created () {
    this.getDicts();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.fatigue-driving {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .item2 {
    margin-top: 60px;
    padding-left: 60px;
  }
  .width0 {
    width: 35%;
  }
  .width1 {
    width: 60%;
  }
  .width2 {
    width: 90%;
  }
}
.theme-project-gps {
  .fatigue-driving {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
    ::v-deep {
      .el-tabs {
        height: 100%;
        box-sizing: border-box;
      }
    }
  }
}
</style>
